import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StudentNote } from '../student-notes-resources/student-note';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { StudentAddCommentToNotePopupComponent } from '../student-add-comment-to-note-popup/student-add-comment-to-note-popup.component';
import { ResourceStudentNotesService } from '../student-notes-resources/resource-student-notes.service';
import { ToastrService } from 'ngx-toastr';
import { UpdateNetesSubjectService } from '../student-notes-resources/update-netes-subject';

@Component({
  selector: 'curr-student-note-sidebar-view',
  templateUrl: './student-note-sidebar-view.component.html',
  styleUrls: ['./student-note-sidebar-view.component.scss']
})
export class StudentNoteSidebarViewComponent implements OnInit {
  @Input() note: StudentNote;
  @Output() deleteNote = new EventEmitter();

  constructor(private noteService: ResourceStudentNotesService,
              private unss: UpdateNetesSubjectService,
              private toastr: ToastrService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  isSimpleNote() {
    return !this.note.selected_image && !this.note.selected_text;
  }

  editNote() {
    const dialogRef = this.dialog.open(StudentAddCommentToNotePopupComponent, {
      width: '522px',
      data: {
        inEditNoteMode: true,
        selectedColor: this.note.selected_text_color,
        comment: this.note.text_note || this.note.image_note
      }
    });

    dialogRef.afterClosed().subscribe(r => {
      if (r && r.comment && r.color) {
        // if (this.note.selected_text) {
        //   this.note.text_note = r.comment;
        // }
        if (this.note.selected_image){
          this.note.image_note = r.comment;
        }

        this.note.text_note = r.comment;
        this.note.selected_text_color = r.color;
        this.updateNote();
      }
    });

  }

  openConfirmDelete() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        questionTitle: 'Delete this note?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.noteService.deleteNote(this.note.id).subscribe(resp => {
          this.deleteNote.emit();
          this.unss.send();
          this.toastr.info('Note deleted!');
        }, this.onError);
      }
    });
  }

  private updateNote() {
    this.noteService.editNote(this.note).subscribe(resp => {
      this.toastr.info('Note updated!');
      this.unss.send();
    }, this.onError);
  }

  private onError(error: any) {
    this.toastr.warning('An error happened!');
  }

  smoothScroll(){
    let el = {};
    if(this.note.selected_image){
      el = document.querySelector(`#ichl-${this.note.id}`);
    }
    if (this.note.selected_text){
      el = document.querySelector(`#hl-${this.note.id}`);
    }
    (el as HTMLElement)?.scrollIntoView({
      behavior: 'smooth'
    });
  }

}
