<!-- Created by Max Bon. on 29/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-student-note-sidebar-view" [ngClass]="[isSimpleNote() ? 'simple-note' : '']" [id]="'snc-'+note.id">
  <div class="first-note-line">
    <mat-icon (click)="smoothScroll()" [svgIcon]="'note-list'" [style.color]="note.selected_text_color"></mat-icon>

    <div *ngIf="!note.selected_image" (click)="smoothScroll()" class="selected-text">{{note.selected_text || 'No selected text'}}</div>

    <div *ngIf="note.selected_image"
      (click)="smoothScroll()"
      class="image-container"  fxLayout="row" fxLayoutAlign="center center"
      [style]="{'border-color': note.selected_text_color, 'background-color': note.selected_text_color}">
      <img [src]="note.selected_image" alt="image">
    </div>

    <div class="options">
      <mat-icon [matMenuTriggerFor]="beforeMenu" (click)="$event.stopPropagation()">more_vert</mat-icon>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="editNote()">
          <mat-icon>edit</mat-icon>
          Edit
        </button>
        <button mat-menu-item (click)="openConfirmDelete()">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="note-text">{{note.text_note || note.image_note || 'No note'}}</div>
</div>
