import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {QuestionsLabels, QuestionType} from '../../../student/test.model';
import { getRestApiProvider, RestApiService } from '../../../shared/api/rest-api.service';
import { COUResponse } from '../../../shared-pages/subject-details/api-subject-details';
import { FileUploadService } from '../../../shared/components/edit-question-block/file-upload.service';
import { LocalStorageService } from 'ngx-localstorage';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ReviewQuestion, SubjectTestSelectTopic } from '../questions-page.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'curr-review-test-question-card',
  templateUrl: './review-test-question-card.component.html',
  providers: [getRestApiProvider<COUResponse>('curriculumCoUQuestions')],
  styleUrls: ['./review-test-question-card.component.scss']
})
export class ReviewTestQuestionCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isCumulativeTests = true;
  @Input() questionNumber: number;
  @Input() set incomingQuestion(value: ReviewQuestion) {
    this.question = value;
  };
  @Input() paperTopics: SubjectTestSelectTopic[];
  @Input() selectedMainQuestionId: number
  @Input() showAddToSessionBtn = true;
  @Input() showTotalMarks = true;
  @Input() showTitle = true;
  @Input() hasChart = false;
  @Input() modeType: string;
  @Input() isNotViewMode: boolean;
  @Input() courseId: number;

  question: ReviewQuestion;
  nextSessionId: any;
  questionType = QuestionType;
  schoolYearCourseId: number;
  cou;
  isFirst = true;
  isLoadingImages = false;
  isLoadingSubInstruction = false;
  isDisabled: boolean = false;
  mainQuestionNumber: string;
  secondQuestion: string;
  questionsLabels = QuestionsLabels;
  filteredTopic: string;
  private subscription = new Subscription();

  constructor(
    private fileUploadService: FileUploadService,
    private localStorageService: LocalStorageService,
    private couService: RestApiService<COUResponse>,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.checkChartExists();
    this.showAddToSessionBtn = this.modeType !== 'view';
    // this.getNextSessionId();

    if (this.question.couId) {
      this.subscription.add(
        this.couService.getById(this.question.couId + '').subscribe(resp => {
          this.cou = (resp as any).question || 'No cou';
        }));
    }

    this.getQuestionDescription(this.question);

    if (this.paperTopics) {
      const filteredTopic = this.paperTopics.find(item => item.id === this.question.curriculumLObjId);
      if (filteredTopic) this.filteredTopic = filteredTopic.title.replace(/<[^>]*>/g, '');
    }
  }

  checkChartExists() {
    if (this.hasChart && this.question.testStatistics) {
      this.hasChart = (this.question.testStatistics.correctAnswers
        + this.question.testStatistics.partiallyCorrectAnswers + this.question.testStatistics.incorrectAnswers) > 0;
    }
  }

  transformImageLink(): void {
    if (this.question.rubric) {
      if (this.question.rubric.includes('src')) {
        const str = this.question.rubric;
        // @ts-ignore
        const srcWithQuotes = this.question?.rubric?.match(/src\=([^\s]*)\s/)[1];
        const src = srcWithQuotes.substring(1, srcWithQuotes.length - 1);

        this.fileUploadService
          .download(src, 'IMAGE')
          .pipe(first()).subscribe((string: any) => {
          // @ts-ignore
          this.question.rubric = str.replace(/src="(.*)"/g, `src='data:image/png;base64, ${string.data.bytes}'`)
        });
      }
    }
  }

  // getNextSessionId() {
  //   // const sessionId = this.localStorageService.get('schoolYearCourseId');
  //   if (this.courseId) {
  //     this.subscription.add(
  //       this.couService.getNextSessionId(this.courseId).subscribe(resp => {
  //         this.nextSessionId = resp;
  //       }));
  //   }
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.question) {
      this.getQuestionDescription(this.question);
      this.transformImageLink();
    }
  }

  prevent(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  private async uploadImages(
    imageKeyValue: any,
    q: any,
    param: string = 'question',
    resultParam: string = 'previewQuestion'
  ) {
    let previewQuestion = q[param];
    // tslint:disable-next-line:forin
    for (const key in imageKeyValue) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(key, 'IMAGE').toPromise())?.data?.bytes;
      imageKeyValue[key] = 'data:image/png;base64,' + bytes;
      previewQuestion = previewQuestion.replace(key, imageKeyValue[key]);
    }
    q[resultParam] = previewQuestion;
    q.question = previewQuestion
    param === 'question' ? (this.isLoadingImages = false) : (this.isLoadingSubInstruction = false);
    this.cdr.detectChanges();
  }

  addTopicTestQuestion() {
    this.question.isDisabled = true;
    const testId = this.route.snapshot.params.ctId || this.route.snapshot.params.saId;

    this.subscription.add(
      this.couService.addQuestionToLesson(testId, this.selectedMainQuestionId)
      .subscribe(response => {
        this.toastr.success('The question was added to lesson');
      })
    );

    // if (this.nextSessionId && this.nextSessionId.tutorialSessionPlanId && this.selectedMainQuestionId && testId && !this.isCumulativeTests) {
    //   this.subscription.add(
    //     this.couService
    //       .addSelfAssessmentTestQuestion(this.nextSessionId.tutorialSessionPlanId, testId, this.selectedMainQuestionId)
    //       .subscribe(() => { }));
    //   this.couService.getTutorialSessionPlanInfo(this.nextSessionId.tutorialSessionId).subscribe(() => {
    //   })
    // } else {
    //   if (this.nextSessionId && this.nextSessionId.tutorialSessionPlanId && this.selectedMainQuestionId && testId && this.isCumulativeTests) {
    //     this.subscription.add(
    //       this.couService.addTopicTestQuestion(this.nextSessionId.tutorialSessionPlanId, testId, this.selectedMainQuestionId).subscribe(response => {
    //       }));
    //   }
    // }
  }

  getQuestionDescription(question: any) {
    if (question.question.includes('<img src')) {
      this.isLoadingImages = true;
      const imageKV = this.fileUploadService.getAllImageKeysFromNote(question.question);
      this.uploadImages(imageKV, question);
    }
    if (question.subInstruction && question.subInstruction.includes('<img src')) {
      this.isLoadingSubInstruction = true;
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.subInstruction);
      this.uploadImages(imageKeyValue, question, 'subInstruction', 'subInstruction');
    }
    if (question.subSecondInstruction && question.subSecondInstruction.includes('<img src')) {
      this.isLoadingSubInstruction = true;
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.subSecondInstruction);
      this.uploadImages(imageKeyValue, question, 'subSecondInstruction', 'subSecondInstruction');
    }
    if (this.question && this.questionNumber) {
      const splitString = this.questionNumber.toString().split('.');
      this.mainQuestionNumber = splitString[0];

      if (splitString.length > 2) {
        this.secondQuestion = splitString[0] + '.' + splitString[1];
      }
    }
  }

  isOpenAnswer() {
    return (
      this.question?.answerType === this.questionType.SELECT_ONE ||
      this.question?.answerType === this.questionType.SELECT_MULTIPLE ||
      this.question?.answerType === this.questionType.TRUE_OR_FALSE
    );
  }

  isAnswerCorrect(i: number) {
    if (!this.question || !this.question.correctAnswerOptions) {
      return false;
    }
    if (this.question?.answerType === this.questionType.SELECT_ONE) {
      return this.question?.correctAnswerOptions[0] === i;
    }
    if (this.question?.answerType === this.questionType.TRUE_OR_FALSE) {
      return this.question?.correctAnswerOptions[0] === i;
    }
    if (this.question?.answerType === this.questionType.SELECT_MULTIPLE) {
      return this.question?.correctAnswerOptions.filter(a => a === i).length;
    }
  }

  getQuestionTypeLabel() {
    return this.questionsLabels[this.question.answerType] || '';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
