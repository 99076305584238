import { Observable, pipe } from 'rxjs';
import { ApplicationHttpClient } from './application-http-client';
import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { BasicResponse } from './basic-response';

export function getRestApiProvider<T>(url: string, providerName?: string) {
  return {
    provide: providerName || RestApiService,
    useFactory: restApiServiceCreator<T>(url),
    deps: [ApplicationHttpClient]
  };
}

export function restApiServiceCreator<T>(endpoint: string) {
  return (http: ApplicationHttpClient) => {
    const result = new RestApiService<T>(http);
    result.url = endpoint;
    return result;
  };
}

export class Paginated {
  step;

  constructor(limit?: number, offset?: number, step?: number) {
    this.limit = limit || 10;
    this.offset = offset || 0;
    this.step = step || 1;
  }
  limit: number;
  offset: number;

  next(): Paginated {
    return new Paginated(this.limit, this.offset + this.step, this.step);
  }
}

@Injectable()
export class RestApiService<T> {
  url: string;

  public constructor(public http: ApplicationHttpClient) {}

  public getAll(params?): Observable<T[]> {
    return this.http.get(`/${this.url}`, { params }).pipe(
      this.getDataFromResponse(),
      map(res => res.items)
    );
  }

  public getAllWithPost(params?): Observable<T[]> {
    return this.http.post(`/${this.url}`, params).pipe(
      this.getDataFromResponse(),
      map(res => res.items)
    );
  }

  public getAllWithPostPaginated(paginated$: Observable<Paginated>, params?): Observable<T[]> {
    return paginated$.pipe(
      mergeMap(paginated => {
        return this.getAllWithPost({
          ...params,
          limit: paginated.limit,
          offset: paginated.offset,
          rows_count: paginated.limit,
          row_offset: paginated.offset
        });
      })
    );
  }

  public getAllPaginated(paginated$: Observable<Paginated>, params?): Observable<T[]> {
    return paginated$.pipe(
      mergeMap(paginated => {
        return this.getAll({ ...params, limit: paginated.limit, offset: paginated.offset });
      })
    );
  }

  public getById(id: string): Observable<T> {
    return this.http.get(`/${this.url}/${id}`).pipe(this.getDataFromResponse());
  }

  public getTopics(id: string, schoolYearCourseId: string): Observable<any> {
    return this.http.get(`/curriculums/${ id }`, { params: { schoolYearCourseId } } as any)
      .pipe(this.getDataFromResponse());
  }

  public getTopicTests(id: string, schoolYearCourseId: string): Observable<any> {
    return this.http.get(`/curriculumCumTests`, { params: { curriculum_paper_id: id, school_year_course_id: schoolYearCourseId } } as any);
  }

  public getNextSessionId(schoolYearCourseId: number) {
    return this.http
      .get(`/tutorial-session/course/${schoolYearCourseId}/getNextSessionId`)
      .pipe(this.getDataFromResponse());
  }
  public getTutorialSessionQuestion(id: number) {
    return this.http
      .get(`/tutorial-session/${id}/questions`)
      .pipe(this.getDataFromResponse());
  }

  public getTutorialSessionPlanInfo(sessionId: number) {
    return this.http
      .get(`/tutorial-session/${sessionId}`)
      .pipe(this.getDataFromResponse());
  }


  public addSelfAssessmentTestQuestion(sessionPlanId, testId, questionId) {
    return this.http
      .post(`/tutorial-session/plan/${sessionPlanId}/sa-test/lo/${testId}/question/${questionId}`, {})
      .pipe(this.getDataFromResponse());
  }

  public addQuestionToLesson(testId, questionId) {
    return this.http
      .post(`/tutorial-session/topic-test/${testId}/question/${questionId}`, {})
      .pipe(this.getDataFromResponse());
  }
  public addTopicTestQuestion(planId, testId, questionId) {
    return this.http
      .post(`/tutorial-session/plan/${planId}/topic-test/${testId}/question/${questionId}`, {})
      .pipe(this.getDataFromResponse());
  }
  public getAllById(id: string, params?): Observable<T> {
    return this.http.get(`/${this.url}/${id}`, { params }).pipe(
      this.getDataFromResponse(),
      map(res => res.items)
    );
  }

  public add(object?: T): Observable<T> {
    return this.http.post(`/${this.url}`, object).pipe(this.getDataFromResponse());
  }

  public update(object: T, id?: string): Observable<T> {
    return this.http.put(`/${this.url}/${id}`, object).pipe(this.getDataFromResponse());
  }

  public updateWithoutId(object: T): Observable<T> {
    return this.http.put(`/${this.url}`, object).pipe(this.getDataFromResponse());
  }

  public addWithFormData(object: T, id?: string): Observable<T> {
    const formData = this.getFormDataFromObject(object);
    return this.http.post(`/${this.url}`, formData).pipe(this.getDataFromResponse());
  }

  public updateWithFormData(object: T, id?: string): Observable<T> {
    const formData = this.getFormDataFromObject(object);
    return this.http.put(`/${this.url}/${id}`, formData).pipe(this.getDataFromResponse());
  }

  public updateWithQueryParams(id: string, object: any, queryParams?: any): Observable<T> {
    return this.http.put(`/${this.url}/${id}`, object, { params: queryParams }).pipe(this.getDataFromResponse());
  }

  public patchFile(object: any, id: string): Observable<T> {
    const formData = this.getFormDataFromObject(object);
    return this.http.patch(`/${this.url}/${id}/file`, formData).pipe(this.getDataFromResponse());
  }

  public patch(params?): Observable<T> {
    return this.http.patch(`/${this.url}`, { params }).pipe(this.getDataFromResponse());
  }

  private getFormDataFromObject(object: any) {
    const formData = new FormData();
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        formData.append(this.snakeToCamel(key), object[key]);
      }
    }
    return formData;
  }

  private snakeToCamel = str =>
    str.replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );

  public remove(id: string): Observable<T> {
    return this.http.delete(`/${this.url}/${id}`);
  }

  public getDataFromResponse = () =>
    pipe(
      map((resp: BasicResponse) => {
        if (resp) {
          return resp.data;
        }
      })
    );
}
