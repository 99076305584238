<!--
 Created by Dmytro Sav. on 5/4/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div currQuestionsWidget></div>

<div class="curr-questions-page">
  <div class="m-b-24">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <div class="page-title-section">
    <div class="page-title-section-left">
      <div class="page-title">
        {{ test.learningObjective || test.testName || 'Test' }} &mdash; {{ isNotViewMode ? 'Review' : 'Report' }}
      </div>
      <div class="page-title-year" *ngIf="test.year">(Year: {{ test.year || '0' }})</div>
    </div>

    <curr-button
      class="share-with-students-btn"
      size="big"
      *ngIf="currSelectedTabIndex === 0 && isNotViewMode"
      [isDisabled]="answersReviewed !== questions.length"
      variant="contained"
      (btnClick)="saveReview()"
      >Share feedback with students</curr-button
    >

    <div class="shared-section" *ngIf="!isNotViewMode">
      <mat-icon [svgIcon]="'circle-check-new'"></mat-icon>
      <span>Feedback Shared</span>
    </div>
  </div>

  <curr-teacher-tabs class="page-tabs" [tabLabels]="tabLabels" [(value)]="currSelectedTabIndex"> </curr-teacher-tabs>

  <div class="info-section" *ngIf="currSelectedTabIndex === 0">
    <curr-expansion-panel
      class="info-section-item test-info-section"
      [isShowed]="true"
      title="Questions Most Frequently Left Unanswered and Incorect"
    >
      <!-- <div class="empty-data" *ngIf="isNotViewMode">
        <img src="/assets/images/colored/empty-chart.svg" />
        <div class="empty-data-text">This field will display upon feedback being shared with students.</div>
      </div> -->

      <curr-teacher-tabs
        [ngClass]="{'blur-section': isNotViewMode}"
        class="page-tabs"
        [tabLabels]="mostFrequentlyLeftTabsLabels"
        [(value)]="currMostFrequentlyLeftTabsIndex"
      >
      </curr-teacher-tabs>

      <curr-most-frequently-left-unanswered-questions
        *ngIf="!isNotViewMode && currMostFrequentlyLeftTabsIndex === 0"
        [data]="frequentlyLeftUnansweredQuestions"
      >
      </curr-most-frequently-left-unanswered-questions>

      <curr-most-frequently-incorrect-questions
        *ngIf="!isNotViewMode && currMostFrequentlyLeftTabsIndex === 1"
        [data]="frequentlyIncorrectQuestions"
      >
      </curr-most-frequently-incorrect-questions>
    </curr-expansion-panel>

    <curr-expansion-panel
      class="info-section-item test-info-section"
      [isShowed]="true"
      title="Questions Types Where Most Marks Are Lost"
    >
      <!-- <div class="empty-data" *ngIf="isNotViewMode">
        <img src="/assets/images/colored/empty-chart.svg" />
        <div class="empty-data-text">This field will display upon feedback being shared with students.</div>
      </div> -->
      <curr-questions-types-where-most-marks-are-lost
        [ngClass]="{'blur-section': isNotViewMode}"
        [data]="questionsTypesWhereMostMarksAreLost"
        [courseId]="courseId"
        [learningObjectiveId]="learningObjectiveId"
      >
      </curr-questions-types-where-most-marks-are-lost>
    </curr-expansion-panel>
  </div>

  <div class="info-section" *ngIf="currSelectedTabIndex === 0">
    <curr-expansion-panel
      class="info-section-item-full test-info-section"
      title="Underlying Factors (Currently limited to student marked responces)"
      [isShowed]="true"
    >
      <!-- <div class="empty-data" *ngIf="isNotViewMode">
        <img src="/assets/images/colored/empty-chart.svg" />
        <div class="empty-data-text">This field will display upon feedback being shared with students.</div>
      </div> -->
      <div class="empty-data" *ngIf="underlyingFactors?.underlyingFactorsStatistics.length === 0">
        <img src="/assets/images/colored/empty-chart.svg" />
        <div class="empty-data-text">No data to show.</div>
      </div>
      <curr-underlying-factors
        [ngClass]="{'blur-section': isNotViewMode}"
        *ngIf="underlyingFactors?.underlyingFactorsStatistics.length > 0"
        [data]="underlyingFactors"
      >
      </curr-underlying-factors>
    </curr-expansion-panel>
  </div>

  <div class="info-section" *ngIf="currSelectedTabIndex === 0">
    <curr-expansion-panel
      class="info-section-item-full test-info-section"
      title="Questions Most Commonly Answered Incorrectly"
      [isShowed]="false"
    >
      <!-- <div class="empty-data" *ngIf="isNotViewMode">
        <img src="/assets/images/colored/empty-chart.svg" />
        <div class="empty-data-text">This field will display upon feedback being shared with students.</div>
      </div> -->

      <ng-container>
        <div
          *ngIf="!(mostIncorrectQuestions?.hardestQuestions.length || mostIncorrectQuestions?.hardestQuestions.length)"
        >
          There is no data
        </div>
        <div *ngIf="mostIncorrectQuestions?.hardestQuestions.length || mostIncorrectQuestions?.hardestQuestions.length">
          <curr-hardest-questions [data]="mostIncorrectQuestions?.hardestQuestions"></curr-hardest-questions>
          <div fxLayout="row">
            <div fxLayout="column">
              <curr-answers-examples [data]="mostIncorrectQuestions?.wrongAnswersExamples"></curr-answers-examples>
            </div>
          </div>
        </div>
      </ng-container>
    </curr-expansion-panel>
  </div>

  <div class="questions-section" *ngIf="currSelectedTabIndex === 0">
    <div class="questions-section-block">
      <div class="title">Questions</div>
      <div class="answers">({{ answersReviewed }}/{{ questionsAmount }} reviewed)</div>
    </div>

    <curr-question-pagination
      [isTTTeacher]="true"
      [questionList]="rawQuestions"
      [checkedQuestions]="checkedPage"
      [isSideBtnVisible]="true"
      [selectedIndex]="currAnswersIndex"
      (questionSelected)="paginationQuestionSelect($event)"
      (paginationLabelsReady)="paginationLabelsReady($event)"
    >
    </curr-question-pagination>

    <div *ngIf="currentQuestion">
      <curr-review-test-question-card
        [questionNumber]="selectedLabel.label"
        [isCumulativeTests]="isCumulativeTests"
        [incomingQuestion]="currentQuestion"
        [courseId]="courseId"
        [paperTopics]="paperTopics"
        [modeType]="modeType"
        [hasChart]="true"
        [isNotViewMode]="isNotViewMode"
        [selectedMainQuestionId]="selectedLabel.questionInfoId"
      >
      </curr-review-test-question-card>

      <div>
        <curr-answer
          class="answer-list"
          [questionNumber]="selectedLabel.label"
          [selectedMainQuestionId]="selectedLabel.questionInfoId"
          [isCumulativeTests]="isCumulativeTests"
          [answersForApprove]="answersForApprove[j]"
          (onAnswersForApprove)="changeApproveList($event, j)"
          (onApproveOne)="approveOne($event, j)"
          *ngFor="let answer of studentAnswers[currAnswersIndex]; let j = index"
          [answerData]="answer"
          [isNotViewMode]="isNotViewMode"
        ></curr-answer>
      </div>

      <div class="approve-all-section" *ngIf="isNotViewMode && answersForApproveBtn">
        <curr-button size="big" [color]="'primary'" (btnClick)="approveAllQuestion()">
          Approve ANSWERS for all students
        </curr-button>
      </div>
    </div>
  </div>

  <div *ngIf="currSelectedTabIndex === 1">
    <curr-students-list
      *ngFor="let studentData of studentsWithQuestions; let i = index"
      [studentData]="studentData"
      [isFirst]="i === 0"
    ></curr-students-list>
  </div>
</div>
