<!--
 Created by Volodymyr Mak. on 15/5/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<article class="profile-card m-b-16" fxLayout="column" fxLayoutAlign="stretch center">
  <section class="user-card" fxLayout="column" fxLayoutAlign="stretch center">
    <div class="edit-btn-wrapper item" fxLayoutAlign="end">
    <input type="file" hidden (change)="fileChangeEvent($event)" #fileInput />
      <div
        (click)="fileInput.click()"
        class="curr-button"
        *ngIf="!editProfile && curUser.role === profileData?.user_type"
      >
        <mat-icon [inline]="true" class="image" [svgIcon]="cameraIcon"></mat-icon>
      </div>
      <div class="avatar item">
        <mat-icon
          [inline]="true"
          *ngIf="!imagePreviewFile"
          class="image"
          [svgIcon]="noProfileIcone"
          style="
        width: 100%; height: 124px; "
        ></mat-icon>
      </div>
      <ng-template #cropImagePopup>
        <div>
          <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Update profile picture</div>
              <mat-icon svgIcon="close" matDialogClose (click)="cancelImageSaving()"></mat-icon>
          </div>
          <div mat-dialog-content class="crop-img-container">
            <image-cropper
                [imageFile]="imageBlob"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                roundCropper="true"
                format="png"
                [resizeToWidth]="144"
                (imageCropped)="getCroppedImg($event)"
            ></image-cropper>
          </div>
          <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
            <curr-button variant="text" color="primary" [style]="cancelButtonStyle" size="small" (btnClick)="cancelImageSaving()">
              Cancel
            </curr-button>
            <curr-button  (btnClick)="saveImage();setCroppedImg($event)">Confirm</curr-button>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="avatar item">
      <mat-icon
        [inline]="true"
        *ngIf="!imagePreviewFile"
        class="image"
        [svgIcon]="noProfileIcone"
        style="width: 100%; height: 124px; "
      ></mat-icon>
      <img *ngIf="imagePreviewFile" [src]="imagePreviewFile" alt="avatar" />
    </div>
    <div class="user-name item">{{ profileData?.first_name }} {{ profileData?.last_name }}</div>
  </section>

  <section class="user-info">
    <div class="info-item">
      <div class="title" fxLayout="row" fxLayoutAlign="stretch center">
        EMAIL
        <mat-icon [svgIcon]="'icon-done'" class="badge m-l-4" [ngClass]="{ verify: true }"> </mat-icon>
      </div>
      <div class="text">
        <span *ngIf="true">{{ profileData?.email }}</span>
      </div>
    </div>
    <div class="info-item" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <div class="info-item-detail full-width">
        <div class="title" fxLayout="row" fxLayoutAlign="stretch center">
          PHONE
        </div>
        <div class="text">
          <span *ngIf="!editUser">{{ profileData?.phone }}</span>
          <div *ngIf="editUser" fxFlex fxLayout="column" class="full-width">
            <curr-input *ngIf="editUser" [(value)]="profileData.phone" inputType="number" placeholder="Phone">
            </curr-input>
            <div fxFlex fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
              <curr-button
                variant="text"
                [style]="cancelButtonStyle"
                color="primary"
                size="small"
                (btnClick)="cancelChanges()"
              >
                CANCEL
              </curr-button>
              <curr-button class="save-btn profile-btn" (btnClick)="updateUserProfile()">
                SAVE
              </curr-button>
            </div>
          </div>
        </div>
      </div>

      <mat-icon
        class="edit-icon"
        *ngIf="!editUser && curUser.role === profileData?.user_type"
        svgIcon="edit"
        (click)="toggleEditUserMode(true)"
      ></mat-icon>
    </div>
  </section>
</article>
