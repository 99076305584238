<!-- Created by Max Bon. on 14/07/2020-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved-->

<div class="test-question-view" *ngIf="question">
  <div class="test-question-view-row m-b-24">
    <div class="view-row-title view-row-title-flex" *ngIf="showTitle">
      <div class="view-row-title-block">
        <span *ngIf="questionNumber">{{question.isMaster ? 'Introduction' : 'Question'}} {{ questionNumber }}</span>
        <div class="markers-list" *ngIf="!question.isMaster">
          <div class="markers-list-item" *ngIf="question.markType">{{ question.markType === 'AI_MARKED' ? 'AI Marked' : 'Self Marked' }}</div>
          <div class="markers-list-item" *ngIf="question.isPastExamQuestion && question.questionYear">
            Exam Board {{ question.questionYear }}{{question.examinationBoard ? ',' : ''}} {{ question.examinationBoard ? question.examinationBoard.name : '' }}
          </div>
        </div>
      </div>

      <curr-button
        *ngIf="showAddToSessionBtn"
        class="tutor-btn"
        size="big"
        [class.border]="question.isDisabled || question.isQuestionInSessionPlan"
        [isDisabled]="question.isDisabled || question.isQuestionInSessionPlan"
        (btnClick)="addTopicTestQuestion()">
        Add to Lesson
      </curr-button>
    </div>
  </div>

  <div class="question-question-with-chart" [class.no-chart]="!hasChart || question.children.length > 0">
    <div class="question-block">
      <div class="test-question-view-row">
        <div class="view-row-question" [currMath]="question.question"></div>
      </div>
      
      <div class="test-question-view-row" *ngIf="filteredTopic">
        <div class="view-row-title">Topic Сovered:</div>
        <div class="view-row-question" [currMath]="filteredTopic"></div>
      </div>
      <div class="test-question-view-row" *ngIf="isOpenAnswer() && question.children.length === 0">
        <div class="view-row-title">Answer(s):</div>
        <div class="view-row-question">
          <div class="answers-list" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
            <div fxLayout="row" fxLayoutAlign="start flex-start" *ngFor="let option of question?.answerOptions; let i = index">
              <mat-icon [class.correct-circle]="isAnswerCorrect(i)"
                        [svgIcon]="!isAnswerCorrect(i) ? 'lens-outlined' : 'lens-filled'" class="circle-question m-r-8"></mat-icon>
              <span class="label-option" [class.correct-label]="isAnswerCorrect(i)" [innerHTML]="option"></span>
            </div>
            <div *ngIf="!question?.answerOptions?.length">
              No setup
            </div>
          </div>
        </div>
      </div>
      <div class="test-question-view-row" *ngIf="!isOpenAnswer() && question.children.length === 0">
        <div class="view-row-title">Answer(s):</div>
        <div class="m-b-16">Answer type - {{ getQuestionTypeLabel() }}</div>
        <div *ngIf="questionType.OPEN_ENDED == question.answerType">
          <div class="view-row-title">Mark Scheme:</div>
          <div class="open-ended-answers">
            <div class="open-ended-answers-row" *ngFor="let keyword of question.oeqKeywords; let i = index">
              <div class="open-ended-cell open-ended-answer">
                <div class="open-ended-title">Answer</div>
                <div class="open-ended-desc" [currMath]="keyword.key"></div>
              </div>
              <div class="open-ended-cell open-ended-mark">
                <div class="open-ended-title">Mark</div>
                <div class="open-ended-desc">{{ keyword.mark }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="test-question-view-row" *ngIf="showTotalMarks && question.children.length === 0">
        <div class="view-row-title total-available-marks">Total Available Marks: <span class="mark-value">{{ question.mark }}</span></div>
      </div>
    </div>

    <div class="question-chart" *ngIf="hasChart && question.children.length === 0">
      <curr-question-statistic [ngClass]="{'blur-section': isNotViewMode}" [data]="question.testStatistics"></curr-question-statistic>
    </div>
  </div>
</div>
